/* You can add global styles to this file, and also import other style files */
$link-color-default: #0000EE;

@font-face
{
  font-family: 'Quicksand';
  src: url('./assets/Quicksand/static/Quicksand-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face
{
  font-family: 'Quicksand';
  src: url('./assets/Quicksand/static/Quicksand-Regular.ttf') format('truetype');
  font-weight: normal;
}

html
{
  font-family: 'Quicksand', sans-serif;
  // This is a mobile specific setting that disables doube-tap to zoom.
  touch-action: manipulation;
}

html,
body
{
  width: 100%;
  height: 100%;
}

body
{
  margin: 0;
  background-color: #f5f5f5;
}

h2
{
  margin: 0;
}

button
{
  border: none;
  font-family: 'Quicksand', sans-serif;
  color: black;
}

a
{
  &:visited
  {
    color: $link-color-default;
  }
}
